:root{
    --background: #f2f5f7;
    --text-primary:#2f4770;
    --text-secondary:#647780;
    --white:#fff;
}
.container{
    max-width: 1140px;
    margin: auto;
}

h1{
    font-size: 42px;
    margin: 0px;
    margin-bottom: 30px;
}
h3{
    padding: 10px;
}
.accordion-simple > .active {
    display: block;
}
.accordion__faq .inactive{
    display: none;
}
.accordion__faq > div {
    background-color: white;
    margin-bottom: 20px;
    padding: 0px 15px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    cursor: pointer;
}
.accordion__title{
    color: var(--text-secondary);
    padding-bottom: 20px;
    font-weight: 500;
   
}
.accordion__faq-heading{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.accordion__faq-heading .active{
    color: var(--text-primary);
}
.accordion__faq-heading h3{
    color: var(--text-secondary);
    font-weight: 600;
}
.accordion__faq p {
    margin: 0px;
    padding-left: 10px;
    padding-bottom: 30px;
    color: var(--text-secondary);
    line-height: 1.4;
}
.hero {
    position: relative;
  }
  .hero h3 {
    font-weight: 100;
    letter-spacing: 2px;
    
  }
  .hero h1 {
    font-size: 60px;
    font-weight: bold;
  }
  h3 {
    color: white;
  }

  h3 span {
    color: #ff014f;
    font-weight: bold;
  }

  .hero h1 span {
    /* color: #ff014f; */
    color: white;
  }
  .hero h2 {
    font-size: 50px;
    font-weight: bold;
   /*  color: #ff014f; */
   color: white;
  }
  .hero h2 span {
    color: black;
  }
  .hero p {
   /*  color: #878e99; */
   color: white;
    font-size: 18px;
    line-height: 30px;
    margin-top: 30px;
  }
  .hero_btn {
    margin-top: 20%;
  }
  .hero_btn h4 {
    font-weight: 500;
    letter-spacing: 2px;
    /* color: #43474b; */
    color: white;
    font-size: 15px;
    margin: 30px 0 30px 0;
  }
  .hero_btn button {
    margin-right: 30px;
  }
  .hero_btn i {
    font-size: 20px;
  }
  
  .hero_btn button img {
    width: 20px;
    height: 20px;
  }
  .hero .left {
    margin-right: 10%;
  }
  .hero .right {
    width: 20%;
    position: relative;
  }
 /*  .hero .right_img {
    background: linear-gradient(145deg, #e2e8ec, #ffffff);
    box-shadow: 4px 4px 8px #cbcbcb, -4px -4px 8px #ffffff;
    border-radius: 10px;
    height: 580px;
    width: 510px;
    margin-top: 10%;
   
  } */
  .hero .right_img img {
    position: absolute;
    top: 0;
    left: 10%;
    width: 510px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
   
    box-shadow: rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px;

    
}
  @media (max-width: 768px) {
    .hero .right,
    .hero .left {
      width: 100%;
      margin-left: 20px;
      margin-top: -70px;  
      line-height: 30px;

    }
    .hero h2 span {
      font-size: 30px;
      line-height: 0;
      
    }
    


    .hero_btn {
      display: none;
    }
  
    .hero .d_flex {
      flex-direction: column;
      flex-direction: column-reverse;
    }
    .hero .right_img {
      width: 100%;
      height: 54vh;
    }
    .hero .right_img img{
      
      width: 300px;
    }
   
    
  }



/*  @media (max-width: 1400px) {
  .hero .right,
  .hero .left {
    width: 100%;
  
  }

  .hero .f_flex {
    flex-direction: column;
    flex-direction: column-reverse;
  }
  .hero .right_img {
    width: 300px;
    height: 34vh;
    
  }
  .hero .right_img img{
    
    width: 300px;
  }
 
  
} */
.main{
width: 100%;

position: relative;  
}
video{
  width: 100%;
  opacity: 0.7;
}
.section{
  position: absolute;
  top: 30%;
  width: 100%;
  height: 100%;
 
}
.Contact .left {
    width: 36%;
  }
  .Contact .right {
    width: 100%;
  }
  .Contact .details{
    padding: 50px 0px;
  }
  .Contact .box {
    padding: 30px;
    
  }
  .Contact .box img {
   width: 100%;
   border-radius: 5px;
  }
  .Contact .details {
    padding: 1rem;
  }

  .Contact .details h1 {
    margin: 20px 0 20px 0;
    font-size: 35px;
  }
  .Contact .details p {
    font-size: 17px;
  }
  .Contact .button {
    margin-top: 20px;
  }
  .Contact button {
    margin-right: 20px;
  }
  
  .Contact .right {
    padding: 35px;
    margin-left: 10px;   
  }
  .Contact .input span {
    font-size: 13px;
  }
  .Contact input {
    margin-top: 10px;
  }
  .Contact .right button {
    width: 100%;
    color: #ff014f;
  }
  .Contact .right button:hover {
    color: white;
  }
  .Contact .right button i {
    margin-left: 10px;
  }
  @media (max-width: 768px) {
    .Contact .d_flex {
      flex-direction: column;
    }
    .Contact .left,
    .Contact .right {
      width: 100%;
      margin-top: 50px;
    }
  }
 /* for desktop */
 .whatsapp_float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #25d366;
  color: #FFF;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
}

.whatsapp-icon {
  margin-top: 16px;
}

/* for mobile */
@media screen and (max-width: 767px) {
  .whatsapp-icon {
      margin-top: 10px;
  }

  .whatsapp_float {
      width: 40px;
      height: 40px;
      bottom: 20px;
      right: 10px;
      font-size: 22px;
  }
}

  
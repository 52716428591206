:root{
    --headingFont: 'Poppins', 'sans-serif' ;
    --paraFont: 'DM Sans', sans-serif;
    --headingColor: #00133E;
    --textColor:#333333;
    --grad1: #F06081;
    --grad2: #01A89E;
    --shadow: 0px 0px 54px -26px rgb(82 82 82 / 50%);
    --bgColor: linear-gradient(30deg, var(--grad1), var(--grad2) 100%);
}


footer{
    background: url("../../public/img/footer1.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
 

  
}
footer .footer-row{
    display: grid;
    grid-template-columns: 1.5fr 1fr 1fr 1fr;
 
}
footer .footer-row .footer-col{
    margin-top: 100px;
    
}

footer .footer-row .footer-col img{
    width: 300px;
    margin: 20px 0px 0px 20px;
}

.padding {
    margin-top: 100px;
   
}
footer .footer-row .footer-col img + p{
color: #00133E;
font-family: var(--paraFont);
font-size: 25px;
font-weight: 400;
margin-top: 20px;
margin-left:20px;
letter-spacing: 0.4px;
z-index: 1;
}
footer .footer-row .footer-col h2 {
    color: #00133E;
    font-family: var(--headingFont);
    font-weight: 700;
    font-size: 30px;   

}
footer .footer-row .footer-col .divider{
    width: 100px;
    height: 5px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    background: var(--bgColor);
    margin: 10px 0;
}
footer .footer-row .footer-col .icon-list{
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    gap:20px;

}
footer .footer-row .footer-col .icon-list a{
    text-decoration:none;
    display: inline-block;
    color: #00133E;
    font-family: var(--paraFont);
    font-weight: 600;
    font-size: 20px;
    letter-spacing: 0.5px;
    transition: 0.3s;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -ms-transition: 0.3s;
    -o-transition: 0.3s;
}
footer .footer-row .footer-col .icon-list a:hover{
    color: var(--grad2);
}
footer .footer-row .footer-col .icon-list a i{
    color: var(--grap2);
    margin-right: 5px;
}
footer .credit{
    text-align: center;
    padding: 15px;
    background: var(--bgColor);
    color: whitesmoke;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    border-top-right-radius: 0;
    margin-top: 100px;
    margin-bottom: -70px;
}
footer .credit p {
    font-family: var(--paraFont);
    font-weight: 400;
    font-size: 17px;
}
footer .credit p a{
    color: black;
    font-weight: 500;
}
/* Responsive View */
@media only screen and (max-width:900px){
    .padding .footer-row{
        grid-template-columns: 1.5fr 1fr 1fr;
        grid-gap:40px;
        
    }
    footer .footer-row .footer-col .icon-list a{
        color: black;
    }
    footer .footer-row .footer-col h2 {
        color: black;
    }
}
@media only screen and (max-width:800px){
    .padding .footer-row{
        grid-template-columns: 1fr 1fr;
        grid-gap:40px
    }
    footer .footer-row .footer-col .icon-list a{
        color: black;
    }
    footer .footer-row .footer-col h2 {
        color: black;
    }
}
@media only screen and (max-width:450px){
    .padding .footer-row{
        grid-template-columns: 1fr;
        grid-gap:40px
    }
    footer .footer-row .footer-col .icon-list a{
        color: black;
    }
    footer .footer-row .footer-col h2 {
        color: black;
    }
}

.Resume .heading h1 {
    font-size: 40px;
  }
  .Resume .content {
    max-width: 85%;
    margin: auto;
  }
  .Resume .box {
    padding: 40px;
  }
  .center{
    text-align: center !important;
  }
  .leftresume{
    margin-left: 12rem;
  }
  .Resume hr {
    margin: 30px 0 30px 0;
    border: 1px solid #e3e9ed;
    background-color: #e3e9ed;
  }
  .Resume .rate .btn_shadow {
    color: #ff014f;
    padding: 15px 30px;
    font-weight: bold;
    transition: 0.5s;
  }
  .Resume p {
    font-size: 18px;
    transition: 0.5s;
  }
  .heading img {
    width: 20%;
  }
  .Resume .box {
    margin-bottom: 40px;
    position: relative;
  }
  .Resume .btn_shadow:hover {
    transform: translate(0);
  }
  .Resume .box:hover .btn_shadow {
    background: #ff3a75;
    box-shadow: none;
    color: white;
  }
  .Resume .box:hover p {
    color: white;
  }
  .Resume .content-section {
    position: relative;
  }
  .Resume .left {
    position: relative;
  }
  .Resume .left::after {
    content: "";
    position: absolute;
    top: 16%;
    left: 0;
    height: 118vh;
    width: 5px;
    background: #d9dee2;
    z-index: -2;
  }
  .Resume .box::after {
    content: "";
    position: absolute;
    top: 13%;
    left: -8%;
    height: 5px;
    width: 50px;
    background: #d9dee2;
  }
  .Resume .box::before {
    content: "";
    position: absolute;
    top: 11%;
    left: -10%;
    height: 10px;
    width: 10px;
    background: #fff;
    border: 5px solid #d9dee2;
    border-radius: 50%;
    z-index: 5;
    transition: 0.5s;
  }
  .Resume .box:hover.box:before {
    background: #ff3a75;
  }
  @media (max-width: 768px) {
    .Resume .d_flex {
      flex-direction: column;
    }
    .Resume .left::after {
      top: 14%;
      height: 105vh;
    }
    .leftresume{
      margin-left: 1rem;
    }
  }
  
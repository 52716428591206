
.table {
    width: 80%;
    border-collapse: collapse;
    margin: 30px auto;
  }
   .table th,
  .table td {
    padding: 8px;
    text-align: left;
    border: 1px solid #ddd;
  }
   .table th {
    background-color: #ff014d64;
    color: white;
  }
   .table tbody tr:nth-child(even) {
    background-color: #f2f2f2;
  }
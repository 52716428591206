.features .box {
    padding: 50px;
   
  }
  .features .box h2 {
    margin: 20px 0 20px 0;
  }
  .features .box p {
    word-spacing: 2px;
    transition: 0.5s;
  }
  .features .box i {
    font-size: 30px;
    margin-top: 20px;
    transition: 0.5s;
    opacity: 0;
  }
  .features .box:hover p {
    color: white;
  }
  .features .box:hover i {
    color: white;
    opacity: 1;
  }
  @media (max-width: 768px) {
    .content{
      display: flex;
      flex-direction: column;
      

    }

  }
  .features .box {
    padding: 50px;
   
  }
  .features .box h2 {
    margin: 20px 0 20px 0;
  }
  .features .box p {
    word-spacing: 2px;
    transition: 0.5s;
  }
  .features .box i {
    font-size: 30px;
    margin-top: 20px;
    transition: 0.5s;
    opacity: 0;
  }
  .features .box:hover p {
    color: white;
  }
  .features .box:hover i {
    color: white;
    opacity: 1;
  }
  
  .modal-blog.modal,
.modal-blog.overlay {
  overflow: scroll;
}
.modal-blog.modal .modal-content {
  position: relative;
  top: 10%;
  left: 0;
  max-width: 70%;
  margin: auto;
  margin-bottom: 10%;
}
.modal-blog .modal-content .left,
.modal-blog .modal-content .right {
  width: 100%;
  padding: 0;
  margin-top: 30px;
}
/*----------blog_contact--------*/

.blog_contact .left,
.blog_contact .right {
  margin: 0 25px 0 0;
}

.img img {
  width: 100px;
}
.modal-img img {
  width: 20%;
}
.modal-img {
  text-align: center;
}

/*----------blog_contact--------*/
@media (max-width: 768px) {
  .modal-blog.modal .modal-content {
    max-width: 90%;
  }
  .modal-blog.modal .modal-content {
    margin-bottom: 20%;
  }
}

